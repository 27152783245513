import { Fragment, useState } from "react";

import { Listbox, Transition } from "@headlessui/react";

import { FiChevronDown } from "components/atoms/Icon/fi/FiChevronDown";
import Img from "components/atoms/Img";

/* ********** */

const FormApplyCompany = ({ data }) => {
  const [country, setCountry] = useState(data[0]);
  const [companyName, setCompanyName] = useState("");

  const handleInput = (event) => {
    setCompanyName(event.target.value);
  };

  const handleSubmit = () => {
    window.location.href = `/onboarding/register?country_id=${country.id}&company_name=${companyName}`;
  };

  return (
    <div className="flex flex-col justify-between rounded-full py-1 pr-1 text-sm xl:h-[50px] xl:flex-row xl:bg-white">
      <div className="flex flex-grow lg:min-w-[260px]">
        <div className="mb-4 w-full whitespace-nowrap rounded-full bg-white xl:mb-0">
          <Listbox
            value={country}
            onChange={(value) => {
              setCountry(value);
            }}
          >
            {({ open }) => (
              <div className="relative text-left ">
                <Listbox.Button
                  as="div"
                  role="button"
                  className="relative w-full cursor-pointer rounded-full bg-white py-2 px-6 pr-10 text-left text-bbc-gray focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                >
                  <div className="flex items-center gap-x-1">
                    <Img
                      src={`/assets/flags/1x1/${country.country_code.toLowerCase()}.svg`}
                      width={24}
                      height={24}
                      className="min-h-[24px] min-w-[24px] rounded-full"
                      alt={country.country_code.toLowerCase()}
                    />
                    <span className="truncate">{country.name}</span>
                  </div>
                  <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2">
                    <FiChevronDown
                      size={20}
                      className={`absolute right-3  float-right transform align-middle text-bbc-brand-500  ${
                        open ? "rotate-180" : "rotate-0"
                      } transition-all duration-200`}
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-3 max-h-60 w-full overflow-auto rounded-2xl bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <div className="overflow-hidden rounded-xl shadow-md">
                      <div className="max-h-40 overflow-y-auto bg-white text-sm">
                        {data.map((item, itemIdx) => (
                          <Listbox.Option
                            key={itemIdx}
                            className="list-none"
                            value={item}
                          >
                            <div
                              className={`flex cursor-pointer items-center gap-x-1 px-4 py-2 ${
                                country.id === item.id
                                  ? "bg-bbc-primary text-white"
                                  : "hover:bg-bbc-primary hover:bg-opacity-40"
                              }`}
                              onClick={() => setCountry(item)}
                            >
                              <Img
                                src={`/assets/flags/1x1/${item.country_code.toLowerCase()}.svg`}
                                width={24}
                                height={24}
                                className="min-h-[24px] min-w-[24px] rounded-full"
                                alt={item.country_code.toLowerCase()}
                              />
                              <span className="truncate">{item.name}</span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </div>
                    </div>
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
      </div>
      <div className="hidden items-center xl:flex">
        <div className="h-6 w-[1px] rounded-full bg-slate-300" />
      </div>
      <div className="flex w-full flex-grow items-center xl:max-w-[190px]">
        <input
          id="ipt-company-name"
          placeholder="Your company name"
          className="mb-4 w-full truncate rounded-full bg-white px-2 py-2 outline-none xl:mb-0"
          value={companyName}
          onChange={handleInput}
        />
      </div>
      <div className="w-full">
        <button
          onClick={() => handleSubmit()}
          className="h-full w-full truncate rounded-full bg-bbc-primary py-2 px-4 text-base text-white lg:text-sm xl:text-base"
        >
          Apply Your Company
        </button>
      </div>
    </div>
  );
};

export default FormApplyCompany;
FormApplyCompany.propTypes = {};

FormApplyCompany.defaultProps = {};
