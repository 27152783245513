/* eslint-disable capitalized-comments */
import { useState } from "react";

import { BsCheck } from "components/atoms/Icon/bs/BsCheck";
import { FaAngleDoubleDown } from "components/atoms/Icon/fa/FaAngleDoubleDown";
import { SiTrustpilot } from "components/atoms/Icon/si/SiTrustpilot";
import LinkRouter from "components/atoms/LinkRouter";
/* ********** */

// eslint-disable-next-line no-unused-vars
const Trustpilot = ({ dataDefault, dataTrustpilot }) => {
  const [isLinkHover, setIsLinkHover] = useState(false);
  // const [dataTrustpilotFetch, setDataTrustpilotFetch] = useState(dataDefault);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.NEXT_PUBLIC_ORDER_API_ENDPOINT}/api/portal/trustpilot`
  //     )
  //     .then((result) => setDataTrustpilotFetch(result?.data))
  //     .catch((e) => console.error(e));
  // }, []);
  // const { data } = dataTrustpilotFetch;
  // ******

  return dataTrustpilot ? (
    <div className="row flex-col lg:flex-row">
      <div className="flex flex-col items-center border-bbc-brand-800 lg:col-auto  lg:items-start">
        <p className="mb-2 text-bbc-30px font-semibold capitalize text-bbc-brand-800">
          Excellent
        </p>

        <p className="mb-2 font-normal text-bbc-brand-800">
          Based on <span className="inline-block w-7 text-center">{"433"}</span>{" "}
          reviews
        </p>

        <div className="mb-1 flex items-center gap-x-[5px]">
          <div className="flex h-7 w-7 items-center justify-center bg-[#21B250]">
            <SiTrustpilot className="text-xl text-white" />
          </div>
          <div className="flex h-7 w-7 items-center justify-center bg-[#21B250]">
            <SiTrustpilot className="text-xl text-white" />
          </div>
          <div className="flex h-7 w-7 items-center justify-center bg-[#21B250]">
            <SiTrustpilot className="text-xl text-white" />
          </div>
          <div className="flex h-7 w-7 items-center justify-center bg-[#21B250]">
            <SiTrustpilot className="text-xl text-white" />
          </div>
          <div className="flex h-7 w-7 items-center justify-center bg-[#21B250]">
            <SiTrustpilot className="text-xl text-white" />
          </div>
        </div>
        <div className="flex items-end gap-x-1">
          <SiTrustpilot className="mb-1 text-4xl text-[#00B67A]" />
          <p className="text-2xl font-semibold text-bbc-brand-800">
            Trustpilot
          </p>
        </div>
      </div>
      <div className="col-auto hidden items-center lg:flex">
        <div
          className=" bg-slate-600"
          style={{ width: "1px", height: "144px" }}
        />
      </div>
      <div className="mt-4 flex flex-col items-center justify-center lg:col-7 lg:mt-0 lg:items-start">
        <div>
          {dataTrustpilot.content.benefits &&
            dataTrustpilot.content.benefits.map((item, index) => {
              return (
                <div key={index} className="mb-2 flex items-start gap-x-2">
                  <div className="mt-1.5 rounded-full bg-[#21B250] p-[1px]">
                    <BsCheck className="text-xs text-white" />
                  </div>
                  <p className="font-medium text-bbc-brand-800">{item}</p>
                </div>
              );
            })}
        </div>
        <div>
          {dataTrustpilot.content.button_placeholder && (
            <div className="mb-2 pt-2">
              <LinkRouter
                className="block rounded-full bg-bbc-primary py-2 px-6 font-medium text-white"
                href={dataTrustpilot.content.link_button_placeholder}
                rel={dataTrustpilot.content.rel_placeholder}
                target={dataTrustpilot.content.target_placeholder}
              >
                {dataTrustpilot.content.button_placeholder}
              </LinkRouter>
            </div>
          )}
          {dataTrustpilot.content.see_package && (
            <div
              className="group flex items-center gap-x-2 pr-4"
              onMouseEnter={() => setIsLinkHover(true)}
              onMouseLeave={() => setIsLinkHover(false)}
            >
              <FaAngleDoubleDown className="text-base text-bbc-primary transition-all group-hover:animate-bounce" />
              <div className="relative">
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    const scrollToTable = document.querySelectorAll(
                      `[id ^= "${dataTrustpilot.content.see_package_id}"]`
                    );

                    scrollToTable.forEach((el) =>
                      window.scrollTo({
                        top: el.getBoundingClientRect().top - 110,
                        behavior: "smooth",
                      })
                    );
                    e.preventDefault();
                  }}
                >
                  <a className="font-medium text-bbc-primary">
                    {dataTrustpilot.content.see_package || ""}
                  </a>
                </div>
                <div
                  className={`absolute h-0.5 w-full origin-left scale-x-0 bg-bbc-primary group-hover:scale-x-100${
                    isLinkHover ? " transition-transform duration-300" : ""
                  }`}
                />
                <div
                  className={`absolute h-0.5 w-full origin-right scale-x-0 bg-bbc-primary delay-200 duration-200 group-hover:scale-x-100${
                    isLinkHover ? "" : " transition-transform duration-300"
                  }`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Trustpilot;
