import PropTypes from "prop-types";

import AiOutlineUser from "components/atoms/Icon/ai/AiOutlineUser";
import { FiBookOpen } from "components/atoms/Icon/fi/FiBookOpen";
import { MdAccessTime } from "components/atoms/Icon/md/MdAccessTime";

/* ********** */

const PostMeta = ({ author, timeRead, date, textProps, iconProps }) => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-x-6 gap-y-3 lg:justify-start">
      {/* Author */}
      {author && (
        <div className="hidden items-center gap-x-2 lg:flex">
          <AiOutlineUser
            className={iconProps.className ? iconProps.className : "text-xl"}
          />
          <p
            className={
              textProps.className ? textProps.className : "text-sm italic"
            }
          >
            {author}
          </p>
        </div>
      )}
      {/* Time Read */}
      {timeRead && (
        <div className="ml-1 flex items-center gap-x-2">
          <FiBookOpen
            className={iconProps.className ? iconProps.className : "text-xl"}
          />
          <p
            className={
              textProps.className ? textProps.className : "text-sm italic"
            }
          >
            {timeRead}
          </p>
        </div>
      )}
      {/* Date */}
      {date && (
        <div className="flex items-center gap-x-1.5">
          <MdAccessTime
            className={iconProps.className ? iconProps.className : "text-xl"}
          />
          <p
            className={
              textProps.className ? textProps.className : "text-sm italic"
            }
          >
            {date}
          </p>
        </div>
      )}
    </div>
  );
};

export default PostMeta;
PostMeta.propTypes = {
  author: PropTypes.string,
  timeRead: PropTypes.string,
  date: PropTypes.string,
  textProps: PropTypes.object,
  iconProps: PropTypes.object,
};

PostMeta.defaultProps = {
  author: null,
  timeRead: null,
  date: null,
  textProps: {},
  iconProps: {},
};
