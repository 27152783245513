import PropTypes from "prop-types";

import { FaChevronRight } from "components/atoms/Icon/fa/FaChevronRight";
import LinkRouter from "components/atoms/LinkRouter";
/* ********** */

const Breadcrumb = ({ items, ...props }) => {
  // ******
  return (
    <div {...props}>
      <div className="text-center lg:text-left">
        {items.map((item, itemIdx) => (
          <div key={itemIdx} className="inline">
            {itemIdx < items.length - 1 ? (
              <>
                {itemIdx > 0 && (
                  <span className="mx-3 align-top">
                    <FaChevronRight className="inline text-xs" />
                  </span>
                )}
                {item.uri && item.uri.trim().length > 0 ? (
                  <LinkRouter
                    href={item.uri}
                    className="text-sm font-medium capitalize hover:text-bbc-secondary-100 lg:text-lg"
                  >
                    {item.title}
                  </LinkRouter>
                ) : (
                  <span className="text-sm font-medium capitalize lg:text-lg">
                    {item.title}
                  </span>
                )}
              </>
            ) : (
              <>
                <span className="mx-3 align-top">
                  <FaChevronRight className="inline text-xs" />
                </span>
                <span
                  className="text-sm font-medium capitalize lg:text-lg"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
Breadcrumb.propTypes = {
  items: PropTypes.array,
};

Breadcrumb.defaultProps = {
  items: [],
};
