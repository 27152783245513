import React from "react";

import PropTypes from "prop-types";

import Breadcrumb from "components/molecules/Breadcrumb";
import FormApplyCompany from "components/molecules/FormApplyCompany";
import Trustpilot from "components/molecules/Trustpilot";

import PostMeta from "./PostMeta";

import Styles from "./Index.module.scss";

const Banner = ({
  children,
  breadcrumbs,
  bgColor,
  bgStyle,
  tag,
  title,
  description,
  author,
  timeRead,
  date,
  leftContent,
  rightContent,
  titleProps,
  descriptionProps,
  dataTrustpilot,
  isApplyCompanyForm,
  leftClassName,
  rightClassName,
  wave,
  minHeightBanner,
  breadcrumbMarginClass,
  ...props
}) => {
  return (
    <div
      className={`${Styles.wrapperBanner} relative mt-[68px] overflow-hidden lg:mt-[38px]`}
    >
      <div
        className={`absolute left-0 top-0 h-full w-full p-0 ${bgColor}`}
        style={bgStyle ? bgStyle : {}}
      />
      <div
        className={`${
          minHeightBanner ? minHeightBanner : "banner-min-h-635"
        } relative lg:pt-[66px]`}
        {...props.bannerCustom}
      >
        <div className="flex-shrink flex-grow pt-6 lg:pt-[70px]">
          <div className="container">
            <div className="row  justify-between">
              <div className={`${leftClassName} z-20`}>
                <>
                  {Array.isArray(breadcrumbs) && (
                    <div
                      className={`${
                        breadcrumbMarginClass
                          ? breadcrumbMarginClass
                          : "mb-6 lg:mb-10"
                      }`}
                    >
                      <Breadcrumb items={breadcrumbs} />
                    </div>
                  )}
                  {tag && (
                    <div className="mb-2 text-center italic lg:text-left">
                      {tag}
                    </div>
                  )}
                  {/* Title ----------- */}
                  {title && typeof title === "string" ? (
                    <div className="mb-6 lg:mb-8">
                      <h1
                        className={
                          titleProps?.className
                            ? titleProps?.className +
                              (titleProps?.textColor
                                ? ` ${titleProps?.textColor}`
                                : "")
                            : `text-center text-4xl font-semibold leading-[58px] lg:text-left lg:text-bbc-48px ${
                                titleProps?.textColor
                                  ? ` ${titleProps?.textColor}`
                                  : ""
                              }`
                        }
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    </div>
                  ) : (
                    title
                  )}
                  {/* Description ----------------------------------------- */}
                  {description &&
                    (React.isValidElement(description) ? (
                      description
                    ) : (
                      <div className="mb-6 text-center lg:mb-10 lg:text-left">
                        <div
                          className={descriptionProps?.className}
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      </div>
                    ))}
                  {/* Trustpilot ----------------------------------------- */}
                  {dataTrustpilot && (
                    <div className={dataTrustpilot.className || "mb-6 lg:mb-8"}>
                      <Trustpilot
                        dataDefault={dataTrustpilot.dataDefault}
                        dataTrustpilot={dataTrustpilot.dataStatic}
                      />
                    </div>
                  )}
                  {/* Form country  ----------------------------------------- */}
                  {isApplyCompanyForm && (
                    <div className="mb-10 lg:mb-10">
                      <FormApplyCompany data={isApplyCompanyForm} />
                    </div>
                  )}
                  {/* author  ----------------------------------------- */}
                  {!author && !timeRead && !date ? (
                    <></>
                  ) : (
                    <div className="mb-6 lg:mb-10">
                      <PostMeta
                        author={author}
                        timeRead={timeRead}
                        date={date}
                      />
                    </div>
                  )}
                </>
                {leftContent}
              </div>
              <div className={rightClassName}>{rightContent}</div>
              {children && <div className="col-12">{children}</div>}
            </div>
          </div>
        </div>
      </div>
      <div>{wave}</div>
    </div>
  );
};

export default Banner;
Banner.propTypes = {
  breadcrumbs: PropTypes.array,
  tag: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  author: PropTypes.string,
  timeRead: PropTypes.string,
  date: PropTypes.string,
  leftContent: PropTypes.any,
  rightContent: PropTypes.any,
  titleProps: PropTypes.object,
  bgColor: PropTypes.string,
  descriptionProps: PropTypes.object,
  bannerPanelProps: PropTypes.object,
  dataTrustpilot: PropTypes.object,
  isApplyCompanyForm: PropTypes.array,
  leftClassName: PropTypes.string,
  rightClassName: PropTypes.string,
};

Banner.defaultProps = {
  breadcrumbs: null,
  tag: null,
  title: null,
  description: null,
  author: null,
  timeRead: null,
  date: null,
  leftContent: null,
  rightContent: null,
  bgColor: "bg-bbc-gradient-10",
  titleProps: {
    className: "banner-default-title",
    textColor: "banner-title-default-color",
  },
  descriptionProps: {},
  bannerPanelProps: {},
  dataTrustpilot: null,
  isApplyCompanyForm: null,
  minHeightBanner: "banner-min-h-635 ",
  leftClassName: "relative col-12 lg:col-6",
  rightClassName: "relative col-12 lg:col-6",
};
